import axios from './instance'

const configToken = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const getFares = async(token, q) => {
    try {
        let url = "/costs"
        if (q !== undefined && q !== '') { url += `?q=${q}` }
        const res = await axios.get(url, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const createFares = async(params, token) => {
    try {
        const res = await axios.post('/costs', params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}
export const updateFares = async(id, params, token) => {
    try {
        const res = await axios.put(`/costs/${id}`, params, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}