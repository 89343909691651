<template>
    <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
        <DataTable
            tableClass="elevation-1"
            :headers="headers"
            :items="products"
        >
            <template #top>
                <v-container fluid>
                    <v-row>
                        <v-col class="d-flex"
                            ><v-toolbar-title>{{
                                $vuetify.breakpoint.smAndUp
                                    ? "Lista de gestion de costos"
                                    : "Lista de costos"
                            }}</v-toolbar-title>
                            <v-divider vertical class="ml-5"></v-divider
                        ></v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-end">
                            <Dialog
                                v-model="createDialog.showDialog"
                                width="1000"
                            >
                                <template #activator="{ on, attrs }">
                                    <Button
                                        text="Nuevo"
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <Card
                                    :fields="fields"
                                    costo
                                    :costos="autocompleteRow"
                                    @cancel="cancelDialog(createDialog)"
                                    acceptText="Crear"
                                    @accept="handlePostProduct"
                                    title="Crear producto"
                                >
                                    <template #content="{ item }">
                                        <TextField
                                            v-if="!item.isCustom"
                                            :prefix="item.prefix"
                                            v-model.number="item.model"
                                            :label="item.label"
                                            :type="item.type"
                                            :maxlength="
                                                item.prefix === '$' && 9
                                            "
                                            @input="
                                                item.label ===
                                                    'ID WooCommerce' &&
                                                    triggerProductById($event)
                                            "
                                        />
                                        <v-switch
                                            v-if="item.isCustom"
                                            v-model="item.model"
                                            label="Customizable"
                                        ></v-switch>
                                    </template>
                                    <template #costoContent="{ item }">
                                        <Autocomplete
                                            return-object
                                            item-text="name"
                                            :items="fares"
                                            v-if="
                                                item.isAutocomplete &&
                                                !item.isButton
                                            "
                                            v-model="item.autocompleteModel"
                                            :label="item.label"
                                        />

                                        <Button
                                            v-if="item.isButton"
                                            text="Sumar"
                                            color="primary"
                                            @click="
                                                addItem(
                                                    autocompleteRow[0]
                                                        .autocompleteModel
                                                )
                                            "
                                        />
                                    </template>
                                    <template #table>
                                        <v-col cols="12">
                                            <v-simple-table
                                                height="250px"
                                                fixed-header
                                            >
                                                <DataTable
                                                    height="240"
                                                    tableClass="table-wrap"
                                                    hide-default-footer
                                                    disable-sort
                                                    :headers="costosHeaders"
                                                    :items="costosTable"
                                                >
                                                    <template
                                                        v-slot:[`item.id`]="{
                                                            item,
                                                        }"
                                                    >
                                                        <Icon
                                                            icon="mdi-delete"
                                                            small
                                                            @click="
                                                                removeItem(
                                                                    item.id_cost,
                                                                    costosTable
                                                                )
                                                            "
                                                        />
                                                    </template>
                                                </DataTable>
                                            </v-simple-table>

                                            <div
                                                class="pa-4 d-flex justify-end"
                                            >
                                                <span
                                                    >Total costos: ${{
                                                        totalCostos
                                                    }}</span
                                                >
                                            </div>
                                            <v-divider></v-divider>
                                            <v-row v-if="isSimple">
                                                <v-col
                                                    cols="3"
                                                    v-for="(
                                                        costo, index
                                                    ) in costos"
                                                    :key="index"
                                                >
                                                    <TextField
                                                        @input="
                                                            calculatePercentage(
                                                                index,
                                                                costo.model,
                                                                totalCostos,
                                                                true
                                                            )
                                                        "
                                                        :prefix="costo.prefix"
                                                        v-model="costo.model"
                                                        :disabled="
                                                            costo.disabled
                                                        "
                                                        :label="costo.label"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                v-else
                                                v-for="(
                                                    variant, index
                                                ) in variationsCosts"
                                                :key="index"
                                            >
                                                <v-col cols="12">
                                                    <span
                                                        class="subtitle-1 font-weight-medium text--secondary"
                                                        >{{
                                                            variant.title
                                                        }}</span
                                                    >
                                                </v-col>
                                                <v-col
                                                    v-for="(
                                                        field, id
                                                    ) in variant.fields"
                                                    :key="id"
                                                    cols="3"
                                                >
                                                    <TextField
                                                        @input="
                                                            calculateVariationsPercentage(
                                                                index,
                                                                id,
                                                                field.model,
                                                                totalCostos
                                                            )
                                                        "
                                                        :prefix="field.prefix"
                                                        v-model.number="
                                                            field.model
                                                        "
                                                        :disabled="
                                                            field.disabled
                                                        "
                                                        :label="field.label"
                                                        persistent-placeholder
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </template>
                                </Card>
                            </Dialog>
                        </v-col>
                    </v-row>
                </v-container>
                <div v-for="(snackbar, index) in snackbars" :key="index">
                    <Snackbar
                        v-model="snackbar.display"
                        :color="snackbar.color"
                        top
                        right
                        class="pt-15"
                    >
                        <span class="color-negro-1 f-body-2 mulish">
                            {{ snackbar.text }}
                        </span>
                        <template v-slot:action="{ attrs }">
                            <Icon
                                v-bind="attrs"
                                color="black"
                                icon="mdi-close"
                                @click="snackbar.display = false"
                            />
                        </template>
                    </Snackbar>
                </div>
            </template>

            <template v-slot:[`item.id_wc`]="{ item }">
                <div class="d-flex align-center">
                    <WooIcon />
                    <span class="ml-4">{{ item.id_wc }}</span>
                </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ transformDate(item.created_at, "LL") }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                {{
                    item.updated_at === null
                        ? "-"
                        : transformDate(item.updated_at, "LL")
                }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
                {{ "$" + item.price }}
            </template>
            <template v-slot:[`item.wholesale_price`]="{ item }">
                {{ "$" + item.wholesale_price }}
            </template>
            <template v-slot:[`item.customizable`]="{ item }">
                <Chip
                    :text="item.customizable === 0 ? 'No' : 'Si'"
                    :color="item.customizable === 1 ? 'primary' : ''"
                />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <Icon
                    small
                    icon="mdi-pencil"
                    class="mr-2"
                    @click="openUpdateDialog(item)"
                />
                <Dialog v-model="item.dialog" grid width="1000">
                    <Card
                        @cancel="
                            item.dialog = false;
                            resetArray();
                        "
                        @accept="handleUpdateProduct(item.id, item)"
                        title="Actualizar producto"
                    >
                        <template #updateContent>
                            <v-container fluid>
                                <v-row align="end">
                                    <v-col cols="12" md="3">
                                        <TextField
                                            v-model.number="updateCopy.id_wc"
                                            label="ID WooCommerce"
                                            @input="triggerProductById($event)"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <TextField
                                            v-model="updateCopy.name"
                                            label="Nombre"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="3"
                                        class="d-flex justify-end"
                                    >
                                        <v-switch
                                            v-model="updateCopy.customizable"
                                            label="Customizable"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="10">
                                        <Autocomplete
                                            return-object
                                            item-text="name"
                                            :items="fares"
                                            v-model="updateCosto"
                                            label="Costos"
                                        />
                                    </v-col>
                                    <v-col md="2" class="d-flex justify-end">
                                        <Button
                                            text="Sumar"
                                            color="primary"
                                            @click="addItemUpdate(updateCosto)"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-simple-table
                                            height="250px"
                                            fixed-header
                                        >
                                            <DataTable
                                                height="240"
                                                tableClass="table-wrap"
                                                hide-default-footer
                                                disable-sort
                                                :headers="costosHeaders"
                                                :items="updTable"
                                            >
                                                <template
                                                    v-slot:[`item.id`]="{
                                                        item,
                                                    }"
                                                >
                                                    <Icon
                                                        icon="mdi-delete"
                                                        small
                                                        @click="
                                                            removeItem(
                                                                item.id_cost,
                                                                updTable
                                                            )
                                                        "
                                                    />
                                                </template>
                                            </DataTable>
                                        </v-simple-table>
                                        <div class="pa-4 d-flex justify-end">
                                            <span
                                                >Total costos: ${{
                                                    updatedCostos
                                                }}</span
                                            >
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isSimple">
                                    <v-col
                                        cols="3"
                                        v-for="(costo, index) in updateCostos"
                                        :key="index"
                                    >
                                        <TextField
                                            @input="
                                                calculatePercentage(
                                                    index,
                                                    costo.model,
                                                    updatedCostos,
                                                    false
                                                )
                                            "
                                            :prefix="costo.prefix"
                                            v-model.number="costo.model"
                                            :disabled="costo.disabled"
                                            :label="costo.label"
                                            persistent-placeholder
                                        />
                                    </v-col>
                                </v-row>
                                <v-row
                                    v-else
                                    v-for="(variant, index) in variationsCosts"
                                    :key="index"
                                >
                                    <v-col cols="12">
                                        <span
                                            class="subtitle-1 font-weight-medium text--secondary"
                                            >{{ variant.title }}</span
                                        >
                                    </v-col>
                                    <v-col
                                        v-for="(field, id) in variant.fields"
                                        :key="id"
                                        cols="3"
                                    >
                                        <TextField
                                            @input="
                                                calculateVariationsPercentage(
                                                    index,
                                                    id,
                                                    field.model,
                                                    updatedCostos
                                                )
                                            "
                                            :prefix="field.prefix"
                                            v-model.number="field.model"
                                            :disabled="field.disabled"
                                            :label="field.label"
                                            persistent-placeholder
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </Card>
                </Dialog>
                <Icon
                    small
                    icon="mdi-plus-circle-multiple-outline"
                    class="mr-2"
                    @click="openDuplicateDialog(item)"
                />
                <Dialog v-model="item.duplicateDialog" grid width="1000">
                    <Card
                        acceptText="Duplicar"
                        title="Duplicar producto"
                        @accept="duplicateProduct(item)"
                        @cancel="item.duplicateDialog = false"
                    >
                        <template #updateContent>
                            <v-container fluid>
                                <v-row align="end">
                                    <v-col cols="12" md="3">
                                        <TextField
                                            v-model.number="duplicateCopy.id_wc"
                                            label="ID WooCommerce"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <TextField
                                            v-model="duplicateCopy.name"
                                            label="Nombre"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="3"
                                        class="d-flex justify-end"
                                    >
                                        <v-switch
                                            v-model="duplicateCopy.customizable"
                                            label="Customizable"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col md="10">
                                        <Autocomplete
                                            return-object
                                            item-text="name"
                                            :items="fares"
                                            v-model="duplicateCosto"
                                            label="Costos"
                                        />
                                    </v-col>
                                    <v-col md="2" class="d-flex justify-end">
                                        <Button
                                            text="Sumar"
                                            color="primary"
                                            @click="
                                                addItemDuplicated(
                                                    duplicateCosto
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-simple-table
                                            height="250px"
                                            fixed-header
                                        >
                                            <DataTable
                                                height="240"
                                                tableClass="table-wrap"
                                                hide-default-footer
                                                disable-sort
                                                :headers="costosHeaders"
                                                :items="duplicateTable"
                                            >
                                                <template
                                                    v-slot:[`item.id`]="{
                                                        item,
                                                    }"
                                                >
                                                    <Icon
                                                        icon="mdi-delete"
                                                        small
                                                        @click="
                                                            removeItem(
                                                                item.id_cost,
                                                                duplicateTable
                                                            )
                                                        "
                                                    />
                                                </template>
                                            </DataTable>
                                        </v-simple-table>
                                        <div class="pa-4 d-flex justify-end">
                                            <span
                                                >Total costos: ${{
                                                    duplicatedCostosTotal
                                                }}</span
                                            >
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="3"
                                        v-for="(
                                            costo, index
                                        ) in duplicateCostos"
                                        :key="index"
                                    >
                                        <TextField
                                            @input="
                                                calculatePercentage(
                                                    index,
                                                    costo.model,
                                                    duplicatedCostosTotal,
                                                    false
                                                )
                                            "
                                            :prefix="costo.prefix"
                                            v-model.number="costo.model"
                                            :disabled="costo.disabled"
                                            :label="costo.label"
                                            persistent-placeholder
                                        />
                                    </v-col>
                                </v-row> </v-container></template
                    ></Card>
                </Dialog>
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "@/components/vuetify/DataTable";
import Button from "@/components/vuetify/Button";
import Icon from "@/components/vuetify/Icon.vue";
import Chip from "@/components/vuetify/Chip.vue";
import Dialog from "@/components/vuetify/Dialog.vue";
import TextField from "@/components/vuetify/TextField.vue";
import Autocomplete from "@/components/vuetify/Autocomplete.vue";
import Select from "@/components/vuetify/Select.vue";
import Card from "@/components/vuetify/Card.vue";
import Snackbar from "@/components/vuetify/Snackbar.vue";
import { getFares } from "@/services/fares.js";
import variant from "@/services/variant.json";
import variations from "@/services/variations.json";
import { EventBus } from "@/Event-bus.js";
import {
    getProducts,
    createProducts,
    updateProducts,
    putProductInWordpress,
} from "@/services/wholesale.js";
import {
    getWoocomerceProductById,
    getWoocomerceVariationsProductById,
    createWoocomerceVariationProduct,
} from "@/services/products.js";
import { WooIcon } from "@/assets/icons";
import { transformDate } from "@/helpers/dateHelper";
import { mapGetters, mapActions, mapState } from "vuex";
import { setTimeout } from "core-js";

export default {
    components: {
        DataTable,
        Button,
        Icon,
        Dialog,
        TextField,
        Card,
        Select,
        Snackbar,
        Chip,
        Autocomplete,
        WooIcon,
    },
    data() {
        return {
            transformDate,
            variantData: variant,
            variationsData: variations,
            allOrders: [],
            search: "",
            filters: {
                date: "",
                status: "",
                user: "",
            },
            timeOut: null,
            isDuplicating: false,
            isUpdating: false,
            updateCosto: null,
            isSimple: true,
            duplicateCosto: null,
            variationsCosts: [],
            updateCostos: [
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje ganancia",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio local",
                },
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje mayorista",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio mayorista",
                },
            ],
            duplicateCostos: [
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje ganancia",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio local",
                },
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje mayorista",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio mayorista",
                },
            ],
            costos: [
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje ganancia",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio local",
                },
                {
                    model: null,
                    md: "3",
                    prefix: "%",
                    type: "number",
                    label: "Porcentaje mayorista",
                },
                {
                    model: 0,
                    disabled: true,
                    md: "3",
                    prefix: "$",
                    type: "number",
                    label: "Precio mayorista",
                },
            ],
            updateCopy: null,
            duplicateCopy: null,
            expanded: [],
            fares: [],
            products: [],
            costosTable: [],
            updTable: [],
            duplicateTable: [],
            fields: [
                {
                    model: "",
                    label: "ID WooCommerce",
                    placeholder: "",
                    type: "number",
                    md: "3",
                },
                {
                    model: "",
                    label: "Nombre",
                    placeholder: "",
                    md: "6",
                },
                {
                    colClass: "d-flex justify-end align-center",
                    isCustom: true,
                    model: false,
                    md: "3",
                },
            ],
            autocompleteRow: [
                {
                    isAutocomplete: true,
                    autocompleteModel: "",
                    md: "10",
                    label: "Costos",
                },
                {
                    colClass: "d-flex justify-end",
                    isButton: true,
                    md: "2",
                },
            ],

            costosHeaders: [
                {
                    text: "Nombre del costo",
                    value: "name",
                },
                {
                    text: "Precio del costo",
                    value: "cost_value",
                    align: "end",
                },
                {
                    text: "",
                    value: "id",
                    align: "end",
                },
            ],
            snackbars: [
                {
                    display: false,
                    text: "",
                    color: "",
                },
                {
                    display: false,
                    text: "",
                    color: "",
                },
            ],
            createDialog: { showDialog: false },
            deleteDialog: { showDialog: false },
            dialog: false,
            headers: [
                {
                    text: "ID WooCommerce",
                    value: "id_wc",
                },
                {
                    text: "Fecha de creacion",
                    value: "created_at",
                },
                {
                    text: "Nombre del producto",
                    value: "name",
                },
                {
                    text: "Precio del producto local",
                    value: "price",
                },
                {
                    text: "Precio del producto mayorista",
                    value: "wholesale_price",
                },
                {
                    text: "Customizable",
                    value: "customizable",
                },
                {
                    text: "",
                    value: "actions",
                },
            ],
        };
    },
    computed: {
        ...mapState(["userToken"]),
        ...mapGetters(["getTextSearch", "getOrdersFilters"]),

        totalCostos() {
            return this.costosTable.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.cost_value;
            }, 0);
        },
        updatedCostos() {
            return this.updTable.reduce((accumulator, currentValue) => {
                return (
                    parseInt(accumulator) + parseInt(currentValue.cost_value)
                );
            }, 0);
        },
        duplicatedCostosTotal() {
            return this.duplicateTable.reduce((accumulator, currentValue) => {
                return (
                    parseInt(accumulator) + parseInt(currentValue.cost_value)
                );
            }, 0);
        },
    },
    methods: {
        ...mapActions(["handleLoading"]),
        cancelDialog(dialog) {
            dialog.showDialog = false;
        },
        resetArray() {
            this.fields[1].model = "";
            this.fields[0].model = "";
            this.isSimple = true;
            if (!this.isSimple) {
                this.variationsCosts = [];
            }
        },
        async getProductById(productId) {
            try {
                this.handleLoading(true);
                const res = await getWoocomerceProductById(productId);
                this.fields[1].model = res.data.name;
                if (res?.data.type === "simple") {
                    this.isSimple = true;
                } else {
                    this.isSimple = false;
                    const resVariation =
                        await getWoocomerceVariationsProductById(productId);
                    const attrs = res.data.attributes.map((el) => ({
                        id: el.id,
                        name: el.name.replace(/seleccionar\s*/i, ""),
                        options: el.options,
                    }));
                    this.variationsCosts = resVariation.data.map(
                        (variation) => {
                            const variationAttributes = variation.attributes;

                            const missingAttrs = attrs.filter(
                                (attr) =>
                                    !variationAttributes.some(
                                        (variationAttr) =>
                                            variationAttr.id === attr.id
                                    )
                            );
                            const pluralize = (name) => {
                                return /[aeiou]$/i.test(name)
                                    ? `${name}s`
                                    : `${name}es`;
                            };
                            const title = [
                                ...missingAttrs.map(
                                    (attr) =>
                                        `${attr.name}: todos los ${pluralize(
                                            attr.name.toLowerCase()
                                        )}`
                                ),
                                ...variationAttributes.map((attr) => {
                                    const correspondingAttr = attrs.find(
                                        (a) => a.id === attr.id
                                    );
                                    const optionExists =
                                        correspondingAttr &&
                                        correspondingAttr.options.includes(
                                            attr.option
                                        );
                                    return optionExists
                                        ? `${correspondingAttr.name}: ${attr.option}`
                                        : `${correspondingAttr.name}: Option not found`;
                                }),
                            ].join(", ");
                            return {
                                title,
                                productId: variant.id,
                                variationId: variation.id,
                                fields: [
                                    {
                                        model: null,
                                        disabled: false,
                                        prefix: "%",
                                        type: "number",
                                        label: "Porcentaje ganancia",
                                    },
                                    {
                                        model: 0,
                                        disabled: true,
                                        prefix: "$",
                                        type: "number",
                                        label: "Precio local",
                                    },
                                    {
                                        model: null,
                                        disabled: false,
                                        prefix: "%",
                                        type: "number",
                                        label: "Porcentaje mayorista",
                                    },
                                    {
                                        model: 0,
                                        disabled: true,
                                        prefix: "$",
                                        type: "number",
                                        label: "Precio mayorista",
                                    },
                                ],
                            };
                        }
                    );
                }
            } catch (error) {
                console.log(error);
                if (error.status === 404) {
                    this.isSimple = true;
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "No se ha encontrado el producto con ese ID";
                }
            } finally {
                this.handleLoading(false);
            }
        },

        addItem(params) {
            if (!params || params === "") return;
            let cost = parseFloat(params.precio);
            this.costosTable.push({
                name: params.name,
                cost_value: cost,
                id_cost: params.id,
            });
            this.autocompleteRow[0].autocompleteModel = "";
        },
        addItemUpdate(params) {
            if (!params || params === "") return;
            let cost = parseFloat(params.precio);
            this.updTable.push({
                name: params.name,
                cost_value: cost,
                id_cost: params.id,
            });
            this.updateCosto = "";
        },

        addItemDuplicated(params) {
            if (!params || params === "") return;
            let cost = parseFloat(params.precio);
            this.duplicateTable.push({
                name: params.name,
                cost_value: cost,
                id_cost: params.id,
            });
            this.duplicateCosto = "";
        },
        removeItem(index, context) {
            const indexToRemove = context.findIndex(
                (item) => item.id_cost === index
            );
            if (indexToRemove !== -1) {
                context.splice(indexToRemove, 1);
            }
        },
        calculateVariationsPercentage(objId, fieldsId, percentage, context) {
            if (fieldsId === 0) {
                this.variationsCosts[objId].fields[1].model = Math.floor(
                    context + (percentage * context) / 100
                );
            } else if (fieldsId === 2) {
                this.variationsCosts[objId].fields[3].model = Math.floor(
                    this.variationsCosts[objId].fields[1].model -
                        (percentage *
                            this.variationsCosts[objId].fields[1].model) /
                            100
                );
            }
        },
        calculatePercentage(index, percentage, context, condition) {
            if (index === 0) {
                if (condition) {
                    this.costos[1].model = Math.floor(
                        context + (percentage * context) / 100
                    );
                } else {
                    this.updateCostos[1].model = Math.floor(
                        context + (percentage * context) / 100
                    );
                    if (this.duplicatedCostosTotal !== 0) {
                        this.duplicateCostos[1].model = Math.floor(
                            context + (percentage * context) / 100
                        );
                    }
                }
            } else if (index === 2) {
                if (condition) {
                    this.costos[3].model = Math.floor(
                        this.costos[1].model -
                            (percentage * this.costos[1].model) / 100
                    );
                } else {
                    this.updateCostos[3].model = Math.floor(
                        this.updateCostos[1].model -
                            (percentage * this.updateCostos[1].model) / 100
                    );
                    if (this.duplicatedCostosTotal !== 0) {
                        this.duplicateCostos[3].model = Math.floor(
                            this.duplicateCostos[1].model -
                                (percentage * this.duplicateCostos[1].model) /
                                    100
                        );
                    }
                }
            }
        },
        async triggerProductById(inputVal) {
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(async () => {
                await this.getProductById(inputVal);
            }, 2500);
        },
        async handlePostProduct() {
            if (this.isSimple) {
                await this.postProduct();
            } else {
                await this.createVariationProduct();
            }
        },
        async handleUpdateProduct(id, params) {
            if (!this.isSimple) {
                await this.updateVariationProduct(params);
            } else {
                await this.updateProducts(id, params);
            }
        },
        async postProduct() {
            try {
                this.handleLoading(true);
                const mappedCosts = this.costosTable.map((el) => ({
                    id_cost: el.id_cost,
                    cost_value: el.cost_value,
                }));
                let transformedData = {
                    name: this.fields[1].model,
                    id_wc: this.fields[0].model,
                    price: this.costos[1].model,
                    wholesale_price: this.costos[3].model,
                    percentage: this.costos[0].model,
                    wholesale_percentage: this.costos[2].model,
                    customizable: this.fields[2].model === true ? 1 : 0,
                    costs: mappedCosts,
                };

                const response = await createProducts(
                    transformedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    this.getProducts();
                    this.cancelDialog(this.createDialog);
                    try {
                        this.handleLoading(true);
                        let id = parseInt(this.fields[0].model);
                        let dataToSend = {
                            regular_price: this.costos[1].model.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value: this.costos[3].model.toString(),
                                },
                            ],
                        };
                        const res = await putProductInWordpress(id, dataToSend);
                        if (res.status === 200 || res.status === 201) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].text =
                                "Los precios se han conectado exitosamente";
                            this.snackbars[0].color = "green";
                        }
                    } catch (error) {
                        if (error.status === 404) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "No se ha encontrado el producto con ese ID";
                        }
                        this.snackbars[0].display = true;
                        this.snackbars[0].color = "red";
                        this.snackbars[0].text = "Error al vincular producto";
                    } finally {
                        this.handleLoading(false);
                    }
                    this.costosTable = [];
                    this.fields[0].model = "";
                    this.fields[1].model = "";
                    this.fields[2].model = false;
                    this.costos[0].model = null;
                    this.costos[2].model = null;
                    this.costos[1].model = null;
                    this.costos[3].model = null;
                    this.snackbars[0].display = true;
                    this.snackbars[0].text =
                        "Los precios han sido creados con exito";
                    this.snackbars[0].color = "green";
                }
            } catch (error) {
                this.snackbars[0].display = true;
                this.snackbars[0].color = "red";
                this.snackbars[0].text = "Error al crear precios";
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },
        async duplicateProduct(params) {
            try {
                this.duplicateCostos.forEach((costo, index) => {
                    this.calculatePercentage(
                        index,
                        costo.model,
                        this.duplicatedCostosTotal,
                        false
                    );
                });
                this.handleLoading(true);
                const mappedUpdatedCosts = this.duplicateTable.map((el) => ({
                    id_cost: el.id_cost,
                    cost_value: el.cost_value,
                }));
                let duplicatedData = {
                    name: this.duplicateCopy.name,
                    id_wc: this.duplicateCopy.id_wc,
                    percentage: this.duplicateCostos[0].model,
                    wholesale_percentage: this.duplicateCostos[2].model,
                    price:
                        this.duplicateCostos[1].model === null
                            ? this.duplicateCopy.price
                            : this.duplicateCostos[1].model,
                    wholesale_price:
                        this.duplicateCostos[3].model === null
                            ? this.duplicateCopy.wholesale_price
                            : this.duplicateCostos[3].model,
                    customizable:
                        this.duplicateCopy.customizable === 1
                            ? 1
                            : this.duplicateCopy.customizable === true
                            ? 1
                            : 0,
                    costs: mappedUpdatedCosts,
                };
                const response = await createProducts(
                    duplicatedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    await this.getProducts();
                    params.duplicateDialog = false;

                    try {
                        this.handleLoading(true);
                        let id = parseInt(this.duplicateCopy.id_wc);
                        let dataToSend = {
                            regular_price:
                                this.duplicateCostos[1].model === null
                                    ? this.duplicateCopy.price.toString()
                                    : this.duplicateCostos[1].model.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value:
                                        this.duplicateCostos[3].model === null
                                            ? this.duplicateCopy.wholesale_price.toString()
                                            : this.duplicateCostos[3].model.toString(),
                                },
                            ],
                        };
                        const res = await putProductInWordpress(id, dataToSend);
                        if (res.status === 200 || res.status === 201) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].text =
                                "Los precios se han conectado exitosamente";
                            this.snackbars[0].color = "green";
                        }
                    } catch (error) {
                        if (error.status === 404) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "No se ha encontrado el producto con ese ID";
                        }
                        this.snackbars[0].display = true;
                        this.snackbars[0].color = "red";
                        this.snackbars[0].text =
                            "Error al vincular producto duplicado";
                    } finally {
                        this.handleLoading(false);
                    }
                    this.handleLoading(false);
                    this.duplicateCostos[1].model = null;
                    this.duplicateCostos[3].model = null;
                    this.snackbars[0].display = true;
                    this.snackbars[0].text =
                        "Los precios han sido cargados con exito";
                    this.snackbars[0].color = "green";
                }
            } catch (error) {
                this.snackbars[0].display = true;
                this.snackbars[0].color = "red";
                this.snackbars[0].text = "Error al duplicar precios";
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },

        async createVariationProduct() {
            try {
                this.handleLoading(true);
                this.variationsCosts.forEach((costo, index) => {
                    costo.fields.forEach((field, id) => {
                        this.calculateVariationsPercentage(
                            index,
                            id,
                            field.model,
                            this.totalCostos
                        );
                    });
                });
                const mappedCosts = this.costosTable.map((el) => ({
                    id_cost: el.id_cost,
                    cost_value: el.cost_value,
                }));
                let transformedData = {
                    name: this.fields[1].model,
                    id_wc: this.fields[0].model,
                    customizable: this.fields[2].model === true ? 1 : 0,
                    costs: mappedCosts,
                    percentage: this.variationsCosts[0].fields[0].model,
                    wholesale_percentage:
                        this.variationsCosts[0].fields[2].model,
                    price: this.variationsCosts[0].fields[1].model,
                    wholesale_price: this.variationsCosts[0].fields[3].model,
                    variations: this.variationsCosts.map((el) => ({
                        id_variation: el.variationId,
                        price: el.fields[1].model,
                        wholesale_price: el.fields[3].model,
                        percentage: el.fields[0].model,
                        wholesale_percentage: el.fields[2].model,
                    })),
                };

                const response = await createProducts(
                    transformedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    for (
                        let index = 0;
                        index < this.variationsCosts.length;
                        index++
                    ) {
                        const params = {
                            regular_price:
                                this.variationsCosts[
                                    index
                                ].fields[1].model.toString(),
                            price: this.variationsCosts[
                                index
                            ].fields[1].model.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value: this.variationsCosts[
                                        index
                                    ].fields[3].model.toString(),
                                },
                            ],
                        };
                        try {
                            const res = await createWoocomerceVariationProduct(
                                this.fields[0].model,
                                this.variationsCosts[index].variationId,
                                params
                            );
                            if (res.status === 200) {
                                this.snackbars[0].display = true;
                                this.snackbars[0].text =
                                    "Los precios se han conectado exitosamente";
                                this.snackbars[0].color = "green";
                            }
                        } catch (error) {
                            console.log(error);
                            if (error.status === 404) {
                                this.snackbars[0].display = true;
                                this.snackbars[0].color = "red";
                                this.snackbars[0].text =
                                    "No se ha encontrado el producto con ese ID";
                            }
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "Error al vincular producto";
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                this.snackbars[0].display = true;
                this.snackbars[0].color = "red";
                this.snackbars[0].text = "Error al cargar producto variable";
            } finally {
                this.cancelDialog(this.createDialog);
                this.handleLoading(false);
                this.getProducts();
                this.fields[0].model = "";
                this.fields[1].model = "";
                this.fields[2].model = false;
                this.variationsCosts = [];
                this.fares = [];
            }
        },
        async updateVariationProduct() {
            try {
                this.handleLoading(true);
                this.variationsCosts.forEach((costo, index) => {
                    costo.fields.forEach((field, id) => {
                        this.calculateVariationsPercentage(
                            index,
                            id,
                            field.model,
                            this.updatedCostos
                        );
                    });
                });
                const mappedUpdatedCosts = this.updTable.map((el) => ({
                    id_cost: el.id_cost,
                    cost_value: el.cost_value,
                }));
                let updatedData = {
                    costs: mappedUpdatedCosts,
                    name: this.updateCopy.name,
                    id_wc: this.updateCopy.id_wc,
                    customizable:
                        this.updateCopy.customizable === 1
                            ? 1
                            : this.updateCopy.customizable === true
                            ? 1
                            : 0,
                    percentage: this.variationsCosts[0].fields[0].model,
                    wholesale_percentage:
                        this.variationsCosts[0].fields[2].model,
                    price: this.variationsCosts[0].fields[1].model,
                    wholesale_price: this.variationsCosts[0].fields[3].model,
                    variations: this.variationsCosts.map((el) => ({
                        id_variation: el.variationId,
                        price: el.fields[1].model,
                        wholesale_price: el.fields[3].model,
                        percentage: el.fields[0].model,
                        wholesale_percentage: el.fields[2].model,
                    })),
                };
                const response = await updateProducts(
                    this.updateCopy.id,
                    updatedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    for (
                        let index = 0;
                        index < this.variationsCosts.length;
                        index++
                    ) {
                        const params = {
                            regular_price:
                                this.variationsCosts[
                                    index
                                ].fields[1].model.toString(),
                            price: this.variationsCosts[
                                index
                            ].fields[1].model.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value: this.variationsCosts[
                                        index
                                    ].fields[3].model.toString(),
                                },
                            ],
                        };
                        try {
                            const res = await createWoocomerceVariationProduct(
                                this.updateCopy.id_wc,
                                this.variationsCosts[index].variationId,
                                params
                            );
                            if (res.status === 200) {
                                this.snackbars[0].display = true;
                                this.snackbars[0].text =
                                    "Los precios se han conectado exitosamente";
                                this.snackbars[0].color = "green";
                            }
                        } catch (error) {
                            console.log(error);
                            if (error.status === 404) {
                                this.snackbars[0].display = true;
                                this.snackbars[0].color = "red";
                                this.snackbars[0].text =
                                    "No se ha encontrado el producto con ese ID";
                            }
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "Error al vincular producto";
                        }
                    }
                    this.updateCostos[1].model = null;
                    this.updateCostos[3].model = null;
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "green";
                    this.snackbars[0].text =
                        "Los precios han sido modificados con exito";
                }
            } catch (error) {
                if (error) {
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "Los precios no han podido ser modificados o vinculados";
                }
                console.log(error);
            } finally {
                this.handleLoading(false);
                this.getProducts();
            }
        },
        async updateProducts(id, params) {
            try {
                this.handleLoading(true);
                const mappedUpdatedCosts = this.updTable.map((el) => ({
                    id_cost: el.id_cost,
                    cost_value: el.cost_value,
                }));
                let updatedData = {
                    name: this.updateCopy.name,
                    id_wc: this.updateCopy.id_wc,
                    percentage: this.updateCostos[0].model,
                    wholesale_percentage: this.updateCostos[2].model,
                    price:
                        this.updateCostos[1].model === null
                            ? this.updateCopy.price
                            : this.updateCostos[1].model,
                    wholesale_price:
                        this.updateCostos[3].model === null
                            ? this.updateCopy.wholesale_price
                            : this.updateCostos[3].model,
                    customizable:
                        this.updateCopy.customizable === 1
                            ? 1
                            : this.updateCopy.customizable === true
                            ? 1
                            : 0,
                    costs: mappedUpdatedCosts,
                };
                const response = await updateProducts(
                    id,
                    updatedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    this.getProducts();
                    params.dialog = false;
                    try {
                        this.handleLoading(true);
                        let id = parseInt(this.updateCopy.id_wc);
                        let dataToSend = {
                            regular_price:
                                this.updateCostos[1].model === null
                                    ? this.updateCopy.price.toString()
                                    : this.updateCostos[1].model.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value:
                                        this.updateCostos[3].model === null
                                            ? this.updateCopy.wholesale_price.toString()
                                            : this.updateCostos[3].model.toString(),
                                },
                            ],
                        };
                        const res = await putProductInWordpress(id, dataToSend);
                        if (res.status === 200 || res.status === 201) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].text =
                                "Los precios se han conectado exitosamente";
                            this.snackbars[0].color = "green";
                        }
                    } catch (error) {
                        console.log(error);
                        if (error.status === 404) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "No se ha encontrado el producto con ese ID";
                        }

                        this.snackbars[0].display = true;
                        this.snackbars[0].color = "red";
                        this.snackbars[0].text = "Error al vincular producto";
                    } finally {
                        this.handleLoading(false);
                    }
                    this.handleLoading(false);
                    this.updateCostos[1].model = null;
                    this.updateCostos[3].model = null;
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "green";
                    this.snackbars[0].text =
                        "Los precios han sido modificados con exito";
                }
            } catch (error) {
                if (error) {
                    params.dialog = false;
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "Los precios no han podido ser modificados o vinculados";
                }
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },
        async getProducts(q) {
            try {
                this.handleLoading(true);
                const response = await getProducts(this.userToken, q);
                if (response.status === 200) {
                    this.handleLoading(false);
                    this.allOrders = response.data;
                    this.products = this.allOrders.map((customer) => ({
                        ...customer,
                        dialog: false,
                        deleteDialog: false,
                        duplicateDialog: false,
                    }));
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getFares() {
            try {
                this.handleLoading(true);
                const response = await getFares(this.userToken);
                if (response.status === 200) {
                    response.data.map((el) => {
                        this.fares.push({
                            name: el.name,
                            precio: el.value,
                            id: el.id,
                        });
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },
        async openUpdateDialog(item) {
            this.isUpdating = true;
            const itemCopy = { ...item };
            this.updateCopy = itemCopy;

            item.dialog = true;
            this.updTable = this.updateCopy.costs.map((el) => ({
                name: el.cost.name,
                cost_value: el.cost_value,
                id_cost: el.id_cost,
            }));
            this.isUpdating = false;
            await this.getProductById(item.id_wc);
            if (this.isSimple) {
                this.updateCostos[0].model = parseInt(item.percentage);
                this.updateCostos[2].model = parseInt(
                    item.wholesale_percentage
                );
                this.updateCostos[1].model = parseInt(item.price);
                this.updateCostos[3].model = parseInt(item.wholesale_price);
            } else {
                this.variationsCosts.forEach((cost) => {
                    const variation = this.updateCopy.variations.find(
                        (variation) =>
                            variation.id_variation === cost.variationId
                    );
                    if (variation) {
                        cost.fields[0].model = variation.percentage;
                        cost.fields[1].model = variation.price;
                        cost.fields[2].model = variation.wholesale_percentage;
                        cost.fields[3].model = variation.wholesale_price;
                    } else {
                        cost.fields[0].model = null;
                        cost.fields[1].model = 0;
                        cost.fields[2].model = null;
                        cost.fields[3].model = 0;
                    }
                });
            }
        },
        openDuplicateDialog(item) {
            this.isDuplicating = true;
            const itemCopy = { ...item };
            this.duplicateCopy = itemCopy;
            this.duplicateCopy.id_wc = "";
            this.duplicateCopy.name = "";
            this.duplicateCostos[0].model = parseInt(item.percentage);
            this.duplicateCostos[2].model = parseInt(item.wholesale_percentage);
            this.duplicateCostos[1].model = parseInt(item.price);
            this.duplicateCostos[3].model = parseInt(item.wholesale_price);
            item.duplicateDialog = true;
            this.duplicateTable = this.duplicateCopy.costs.map((el) => ({
                name: el.cost.name,
                cost_value: el.cost_value,
                id_cost: el.id_cost,
            }));
            // console.log(this.updateCopy, "update copy");
            this.isDuplicating = false;
        },
    },
    async created() {
        await this.getProducts();
        await this.getFares();
        this.search = this.getTextSearch;
        EventBus.$on("doSearch", async () => {
            this.search = this.getTextSearch;
            this.handleLoading(true);
            await this.getProducts(this.search);
            this.handleLoading(false);
        });
    },
    watch: {
        updatedCostos: {
            immediate: true,
            handler(newValue, oldValue) {
                if (!this.isUpdating && newValue !== oldValue) {
                    if (this.isSimple) {
                        this.updateCostos.forEach((costo, index) => {
                            this.calculatePercentage(
                                index,
                                costo.model,
                                this.updatedCostos,
                                false
                            );
                        });
                    } else {
                        this.variationsCosts.forEach((costo, index) => {
                            costo.fields.forEach((field, id) => {
                                this.calculateVariationsPercentage(
                                    index,
                                    id,
                                    field.model,
                                    newValue
                                );
                            });
                        });
                    }
                }
            },
        },
        duplicatedCostosTotal: {
            immediate: true,
            handler(newValue, oldValue) {
                if (!this.isDuplicating && newValue !== oldValue) {
                    this.duplicateCostos.forEach((costo, index) => {
                        this.calculatePercentage(
                            index,
                            costo.model,
                            this.duplicatedCostosTotal,
                            false
                        );
                    });
                }
            },
        },
        totalCostos: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (this.isSimple) {
                        this.costos.forEach((costo, index) => {
                            this.calculatePercentage(
                                index,
                                costo.model,
                                this.totalCostos,
                                true
                            );
                        });
                    } else {
                        this.variationsCosts.forEach((costo, index) => {
                            costo.fields.forEach((field, id) => {
                                this.calculateVariationsPercentage(
                                    index,
                                    id,
                                    field.model,
                                    newValue
                                );
                            });
                        });
                    }
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.table-wrap {
    border-radius: 8px;
    border: solid #ced0d6 1px !important;
}
</style>
