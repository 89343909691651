import axios from './instance'

const configToken = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

export const getAllProducts = async(token, page, q, dates, status, user) => {
    try {
        // filtros resueltos en orders pueden servir para productos en caso de necesitar filtrar
        /* let URL = `/orders?perPage=30&page=${page}`
        if (dates !== undefined && dates !== '') { URL += `&betweendates=${dates}` }
        if (status !== undefined && status !== '' && status !== 0) { URL += `&orderstatus=${status}` }
        if (user !== undefined && user !== '' && user !== 0) { URL += `&users=${user}` }
        if (q !== undefined && q !== '') { URL += `&q=${q}` } */
        const URL = '/new_products'
        const res = await axios.get(URL, configToken(token))
        return res
    } catch (err) {
        console.log('error', err)
        throw err.response
    }
}

export const getProductsBySpecificFare = async(id, token) => {
    try {
        const res = await axios.get(`/products-by-cost/${id}`, configToken(token))
        return res
    } catch (err) {
        throw err.response
    }
}

export const getWoocomerceProductById = async(id) => {
    try {
        const res = await axios.get(`${process.env.VUE_APP_WORDPRESS_CONNECTION_URL}${id}`, {
            auth: {
                username: process.env.VUE_APP_WORDPRESS_AUTH_USERNAME,
                password: process.env.VUE_APP_WORDPRESS_AUTH_PASSWORD
            }
        })
        return res
    } catch (err) {
        console.log(err)
        throw err.response
    }
}

export const getWoocomerceVariationsProductById = async(id) => {
    try {
        const res = await axios.get(`${process.env.VUE_APP_WORDPRESS_CONNECTION_URL}${id}/variations`, {
            auth: {
                username: process.env.VUE_APP_WORDPRESS_AUTH_USERNAME,
                password: process.env.VUE_APP_WORDPRESS_AUTH_PASSWORD
            }
        })
        return res
    } catch (err) {
        console.log(err)
        throw err.response
    }
}

export const createWoocomerceVariationProduct = async(productId, variationId, params) => {
    try {
        const res = await axios.put(`${process.env.VUE_APP_WORDPRESS_CONNECTION_URL}${productId}/variations/${variationId}`, params, {
            auth: {
                username: process.env.VUE_APP_WORDPRESS_AUTH_USERNAME,
                password: process.env.VUE_APP_WORDPRESS_AUTH_PASSWORD
            }
        })
        return res
    } catch (err) {
        console.log(err)
        throw err.response
    }
}

export const createProduct = async(data, token) => {
    try {
        const res = await axios.post('/new_products', data, configToken(token))
        return res
    } catch (err) {
        console.log('error', err)
        throw err.response
    }
}

export const updateProduct = async(id, data, token) => {
    try {
        const res = await axios.post(`/new_products/${id}`, data, configToken(token))
        return res
    } catch (err) {
        console.log('error', err)
        throw err.response
    }
}