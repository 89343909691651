<template>
    <v-autocomplete v-bind="$attrs" v-on="$listeners" :class="autoClass">
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        autoClass: {
            type: String,
            default: "",
        },
    },
};
</script>
