<template>
    <v-chip v-bind="$attrs" v-on="$listeners" :class="chipClass">{{
        text
    }}</v-chip>
</template>

<script>
export default {
    props: {
        chipClass: {
            type: [Array, String],
            required: false,
        },
        text: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>
